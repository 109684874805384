
import { useEffect, useState, Suspense, lazy } from "react";
import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga4";
import { reactGA, setCookieAndLocalStorage } from "../common/authTokenHandler";
import { baseUrl} from "../routes";
import { getToken } from "../common/authTokenHandler";
import { StopBackWrapper } from "./stopBackWrapper";
import { useComparisonData } from "../helpers/comparisonContext";
import Loading from "../common/Loading";

const AgencyLogin = lazy(() => import("../auth/Login/Agency_Login"));
const AgencyRegisteration = lazy(() => import("../auth/Registeration/Agency_Registeration"));
const TeamMateRegistration = lazy(() => import("../auth/Registeration/teamMaterRegister"));
const ForgotPassword = lazy(() => import("../auth/Login/forgotPassword"));
const ResetPassword = lazy(() => import("../auth/Login/resetPasword"));
const SubscriptionPlan = lazy(() => import("../components/Subscription/subscriptionPlan"));
const Billing = lazy(() => import("../components/profile/billing"));
const InvoiceDetails = lazy(() => import("../components/profile/invoiceDetails"));
const Payments = lazy(() => import("../components/profile/payments"));
const CommonProfileLayout = lazy(() => import("../common/dashboard_common/commonProfileLayout"));
const DashBoardLayout = lazy(() => import("../layout/dashboardLayout"));
const StripeComponent = lazy(() => import("../helpers/StripeComponent"));
const Accessibility = lazy(() => import("../components/Accessibility/Accessibility"));
const AdvancedPerformanceMetrics = lazy(() => import("../components/AdvancedPerformanceMetrics/advancedPerformanceMetrics"));
const AdvancedSecurity = lazy(() => import("../components/AdvanceSecurity/advancedSecurity"));
const BrandingVisual = lazy(() => import("../components/Branding/Branding_Visual"));
const BusinessPresence = lazy(() => import("../components/Business_Presence/Business_Presence"));
const ContentInfo = lazy(() => import("../components/Content and Information/Content_Info"));
const MainDash = lazy(() => import("../components/DashBoard/Dashboard_Landing"));
const CompareNew = lazy(() => import("../components/Dashboard_Compare/CompareNew"));
const IntegrationService = lazy(() => import("../components/Integration_Service/Integration_Service"));
const InteractivityEngagement = lazy(() => import("../components/Interactivity_Engagement/Interactivity_Engagement"));
const MobileResponsive = lazy(() => import("../components/Mobile Responsivenes/Mobile_Responsive"));
const NavigationComponent = lazy(() => import("../components/Navigation/Navigation"));
const PerformanceTechnical = lazy(() => import("../components/Performance and Technical/Performance_Technical"));
const SecurityPrivacy = lazy(() => import("../components/Security and Privacy/Security_Privacy"));
const Seo = lazy(() => import("../components/Seo/Seo"));
const ManageWebsiteList = lazy(() => import("../common/websitListTable"));
const MyTeamDetails = lazy(() => import("../components/MyTeam/myTeamDetails"));
const InviteTeammate = lazy(() => import("../components/MyTeam/myTeammate"));
const ControlPanelLayout = lazy(() => import("../layout/controlPanelLayout"));
const UserInfo = lazy(() => import("../components/profile/UserInfo"));

export const routes = [
  { id: 1, route: "/dashboard/:id", element: <MainDash /> },
  { id: 2, route: "/accessibility/:id", element: <Accessibility /> },
  { id: 3, route: "/branding_visual/:id", element: <BrandingVisual /> },
  { id: 4, route: "/security_privacy/:id", element: <SecurityPrivacy /> },
  { id: 5, route: "/performance_technical/:id", element: <PerformanceTechnical /> },
  { id: 6, route: "/seo/:id", element: <Seo /> },
  { id: 7, route: "/mobile_responsive/:id", element: <MobileResponsive /> },
  { id: 8, route: "/content_info/:id", element: <ContentInfo /> },
  { id: 9, route: "/interactivity_engagement/:id", element: <InteractivityEngagement /> },
  { id: 10, route: "/integration_service/:id", element: <IntegrationService /> },
  { id: 11, route: "/navigation/:id", element: <NavigationComponent /> },
  { id: 12, route: "/business_presence/:id", element: <BusinessPresence /> },
  { id: 13, route: "/advancedPerformanceMetrics/:id", element: <AdvancedPerformanceMetrics /> },
  { id: 14, route: "/advancedSecurity/:id", element: <AdvancedSecurity/> },
  { id: 15, route: "/compareSite/:id", element: <CompareNew /> },
];


ReactGA.initialize(reactGA());

function AllRoutes() {
  const navigation = useNavigate();
  const location = useLocation();
  const pathName = location?.pathname
  const [userId, setUserId] = useState(getToken(`checkWeb_userId`));
  const [siteName, setSiteName] = useState(``);
  const { setUsersSites } = useComparisonData();
  const signupPaths = ["/agencyRegister", "/subscriptionPlan", "/subscription/payment", "/dashboard/billing", "/dashboard/invoices", "/SubscriptionPlan", "/dashboard/payments"];


  useEffect(() => {
    const authToken = getToken("checkWeb_authToken")
    if (signupPaths.includes(pathName) && !authToken) {
      navigation("/register");
    }
  }, [siteName]);


  useEffect(() => {
    setUserId(getToken("checkWeb_userId"));
  }, []);

  const getSiteId = (e) => {
    axios
      .get(`${baseUrl}api/sites/byuser?user=${userId}`, {
        headers: {
          // "auth-token": localStorage.getItem(`checkWeb_authToken`),
          "auth-token": getToken("checkWeb_authToken"),
        },
      })
      .then((res) => {
        // console.log("res",res)
        // console.log("resin app", res)
        setCookieAndLocalStorage(`checkWeb_siteLength`,res?.data?.data?.length);
        setSiteName(res?.data?.data[0]?.name);
        setUsersSites(res?.data?.data);
      })
      .catch((error) => {
        console.log("Error occured in getting site name", error);
      });
  };

  React.useEffect(() => {
    if (userId === undefined) return;
    else if (userId === null) return;
    else if (userId === "") return;
    else {
      getSiteId();
    }
  }, [userId]);
  // https://app.websiteranking.ai/accept-invite?email=panchram%40adgonline.in&id=gxqPUqxgXsmuX49j&timestamp=1722232995

  return (
    <>
      <StopBackWrapper stopPaths={['/subscriptionPlan', 'SubscriptionPlan', 'subscription/payment', '/dashboard']}>
      <Suspense fallback={ <Loading/> }>
        <Routes>
          <Route path="/" element={siteName?.length > 0 ? <Navigate to={`/dashboard/${siteName}`} /> : <Navigate to="/register" />} />
          <Route path="/agencyLogin" element={siteName?.length > 0 ? (<Navigate to={`/dashboard/${siteName}`} />) : (<AgencyLogin title={`user`} link={`/login`} />)} />
          <Route path="/dashboard" element={siteName?.length > 0 ? <Navigate to={`/dashboard/${siteName}`} /> : <Navigate to="/register" />} />
          <Route path="/login" element={siteName?.length > 0 ? (<Navigate to={`/dashboard/${siteName}`} />) : (<AgencyLogin title={`Agency`} link={`/agencyLogin`} />)} />
          <Route path="/register" element={siteName?.length > 0 ? (<Navigate to={`/dashboard/${siteName}`} />) : (<AgencyRegisteration title={`Agency`} link={`/agencyRegister`} />)} />
          <Route path="/forgotPassword" element={<ForgotPassword title={`user`} link={`/login`} />} />
          <Route path="/resetPassword/:resetPasswordToken" element={<ResetPassword title={`user`} link={`/login`} />} />
          <Route path="/accept-invite/:token" element={<TeamMateRegistration />} />
          <Route path="/agencyRegister" element={<AgencyRegisteration />} />
          <Route path="/subscriptionPlan" element={<SubscriptionPlan />} />
          <Route path="/subscription/payment" element={<StripeComponent type={"payment"} />} />
          <Route path="/" element = {<ControlPanelLayout/>}>
          <Route path="/dashboard/webSiteDetails" element={< ManageWebsiteList/>} />
            <Route path="/dashboard/myteam" element={< MyTeamDetails/>} />
            <Route path="/dashboard/myteammate" element={< InviteTeammate/>} />
            <Route path="/dashboard/userinfo" element={<UserInfo type={"user-info"} />}  />
        
          <Route path="/" element={<CommonProfileLayout />}>
            <Route path="/dashboard/billing" element={<Billing />} />
            <Route path="/dashboard/invoices" element={<InvoiceDetails />} />
            <Route path="/dashboard/payments" element={<Payments />} />         
            <Route path="/dashboard/changeSubscriptionPlan" element={<StripeComponent type={"subPlan"} />} />
            <Route path="/dashboard/changeCard" element={<StripeComponent type={"change"} />}  />
            </Route>
          </Route>
          <Route path="/" element={<DashBoardLayout />}>
          {routes?.map((route) => {
            return <Route key={route?.route} path={route?.route} element={route?.element} />
          })}
          </Route>
          <Route path="*" element={<Navigate to="/register" replace />} />
        </Routes>
        </Suspense>
      </StopBackWrapper>
    </>
  );
}

export default AllRoutes;
