const Loading = ({ isReportDownloading }) => {
  const loadingText=()=>{
    if(isReportDownloading){
      return(
      <textPath href="#path-circle">
      <tspan x="10">Please wait your Report is generating</tspan>
    </textPath>
    )
    }else {
      return(
         <textPath href="#path-circle">
      <tspan x="10">Getting basic information</tspan>
      <tspan x="47" dx="30">
        Evaluating performance
      </tspan>
      <tspan x="77" dx="60">
        Evaluating security
      </tspan>
      <tspan x="107" dx="90">
        Evaluating content
      </tspan>
      <tspan x="107" dx="90">
        Evaluating responsiveness etc
      </tspan>
    </textPath>
    )
    }
  }

  return (
    <>
      <div className="loading-area ">
        <div className="outside-container">
          <div className="inside-spinner"></div>
          <div className="outside-spinner"></div>
          <div className="lodingImg">
            <img src="/assets/images/logo_icon.png" alt="logo-icon"/>
          </div>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              d="
            M 100, 100
            m -75, 0
            a 75,75 0 1,0 150,0
            a 75,75 0 1,0 -150,0"
              id="path-circle"
              fill="none"
            />
            <text>
              {loadingText()}
            </text>
          </svg>
        </div>
      </div>
    </>
  );
};

export default Loading;
